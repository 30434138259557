<template>
    <MainFunctions :buttons="buttons" :title="$t('menu.nav-traveldocshome-title')"></MainFunctions>
</template>

<script>
import store from '@/store'
import MainFunctions from "@/components/MainFunctions"
import { HOMETYPES } from '@/utils/utils'

export default {
    name: 'docshostMenu',
    components: {
        MainFunctions
    },
    data() {
        store.commit('setPageHome', HOMETYPES.TRAVELDOCS)
        return {
            buttons: [
                { title: 'menu.buttons.docsclerk-takeout', color: "cyan", icon: 'local_shipping', routeName: 'docsClerkTakeout'},
                { title: 'menu.buttons.docsclerk-putin', color: "green", icon: 'replay', routeName: 'docsClerkPutin'},
                { title: 'menu.buttons.docsclerk-package-list', color: "deep-orange accent-3", icon: 'inventory', routeName: 'docsClerkPackageList'},
                { title: 'menu.buttons.docsclerk-client-package-list', color: "purple accent-2", icon: 'pending', routeName: 'docsClientPackageList'},
                { title: 'menu.buttons.docsclerk-client-package-fin-list', color: "#6D4C41", icon: 'logout', routeName: 'docsClientPackageFinList'}
            ]
        }
    },
    activated() {
        store.commit('setPageHome', HOMETYPES.TRAVELDOCS)
    }
}
</script>
